import { Helmet } from "react-helmet";

import { AboutBanner } from "component/About/AboutBanner";
import { AboutTCS } from "component/About/AboutTCS";
import { OurFounder } from "component/About/OurFounder";
import { BoardOfAdvisors } from "component/About/BoardOfAdvisors";
import { YoungProfessionals } from "component/About/YoungProfessionals";
import { SocialEquity } from "component/About/SocialEquity";
import { AboutSponsors } from "component/About/AboutSponsors";
import { AboutPartners } from "component/About/AboutPartners";

export const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
      </Helmet>
      <div id="AboutUs">
        <AboutBanner />
        <AboutTCS />
        <OurFounder />
        <BoardOfAdvisors />
        <YoungProfessionals />
        <SocialEquity />
        <AboutSponsors />
        <AboutPartners />
      </div>
    </>
  );
};
