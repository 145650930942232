import { Fragment } from 'react'

import quantumComputing from 'assets/img/home/upcoming-programs-5.jpg'
import dataScienceResearch from 'assets/img/home/k-12-research-opportunity.jpg'
import summerCamps from 'assets/img/home/upcoming-program-summer-camps.jpg'
import artificialIntelligence from 'assets/img/home/upcoming-programs-1-new.jpg'

import quantumComputingLab from 'assets/img/home/quantum-computing-lab.png'
import quantumImmersion from 'assets/img/summer-programs/uni-quantum-career.jpg'

import ppeArtificialIntelligence from 'assets/img/home/upcoming-programs-3-new.jpg'
import ppeQuantumComputing from 'assets/img/home/educator-quantum-computing.jpg'
import aiCloud from 'assets/img/summer-programs/ai-cloud.jpg'

export const programPost = [
  // {
  //   id: '1',
  //   title: 'Virtual Summer Camps',
  //   subTitle: 'Grades: 6 - 12',
  //   list: [
  //     { id: '1', listItem: 'Skills in emerging technologies' },
  //     {
  //       id: '2',
  //       listItem: 'AI, Cybersecurity, Arts + Tech & more',
  //     },
  //     { id: '3', listItem: 'Create specialized tech projects' },
  //     { id: '4', listItem: 'Taught by tech professionals' },
  //   ],
  //   img: summerCamps,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: '/summer-programs#virtualSummerCamps',
  //       isExternal: false,
  //       isHashLink: true,
  //     },
  //     {
  //       text: 'Register',
  //       link: '/summer-camp-registration',
  //       isExternal: false,
  //     },
  //   ],
  // },
  // {
  //   id: '2',
  //   title: 'Data Science Research',
  //   subTitle: 'Grades: 10 - 13',
  //   list: [
  //     { id: '1', listItem: 'Columbia University professors' },
  //     { id: '2', listItem: 'Develop in-depth data science skills' },
  //     { id: '3', listItem: 'Conduct real-world research' },
  //     { id: '4', listItem: 'Increase college competitiveness' },
  //   ],
  //   img: dataScienceResearch,
  //   links: [
  //     { text: 'Learn More', link: '/research', isExternal: false },
  //     { text: 'Apply', link: '/dsrp-application', isExternal: false },
  //   ],
  // },
  {
    id: '3',
    title: 'Quantum Computing',
    subTitle: (
      <Fragment>
        Grades: 9 - 12 & above
        <br />
        Sept 2024 - April 2025
      </Fragment>
    ),
    list: [
      { id: '1', listItem: 'Semester & year-long course options' },
      { id: '2', listItem: 'Learn real quantum skills' },
      {
        id: '3',
        listItem: 'Led by Stanford PhDs',
      },
      { id: '4', listItem: 'Earn transcript credit' },
      { id: '5', listItem: 'Code on a quantum computer!' },
    ],
    img: quantumComputing,
    links: [
      {
        text: 'Learn More',
        link: 'https://www.qubitbyqubit.org/programs',
        isExternal: true,
      },
      {
        text: 'Register',
        link: '/course-application',
        isExternal: false,
      },
    ],
  },
  {
    id: '4',
    title: 'Artificial Intelligence',
    subTitle: (
      <Fragment>
        Grades: 9 - 12 <br /> Sept 2024 - April 2025
      </Fragment>
    ),
    list: [
      { id: '1', listItem: 'Semester & year-long course options' },
      { id: '2', listItem: 'For-credit course' },
      { id: '3', listItem: 'In-depth skills in AI/ML' },
      { id: '4', listItem: 'AI capstone project' },
      { id: '5', listItem: 'Led by Columbia University researchers' },
    ],
    img: artificialIntelligence,
    links: [
      { text: 'Learn More', link: '/train', isExternal: false },
      { text: 'Register', link: '/course-application', isExternal: false },
    ],
  },
]

export const programPostUniversity = [
  {
    id: '1',
    title: 'Quantum Computing',
    subTitle: 'Sept 2024 - April 2025',
    list: [
      { id: '1', listItem: 'Semester & year-long course options' },
      { id: '2', listItem: 'Learn real quantum skills' },
      { id: '3', listItem: 'Run code on a quantum computer' },
      { id: '4', listItem: 'Industry expert Q&As' },
    ],
    img: quantumComputingLab,
    links: [
      {
        text: 'Learn More',
        link: 'https://www.qubitbyqubit.org/programs',
        isExternal: true,
      },
      {
        text: 'Register',
        link: '/course-application',
        isExternal: false,
      },
    ],
  },
  // {
  //   id: '2',
  //   title: 'Early Quantum Career Immersion',
  //   list: [
  //     {
  //       id: '1',
  //       listItem: (
  //         <Fragment>
  //           Complete a research project or be <br />
  //           placed in an industry internship
  //         </Fragment>
  //       ),
  //     },
  //     {
  //       id: '2',
  //       listItem: (
  //         <Fragment>
  //           Develop critical skills for <br />a future career in quantum
  //         </Fragment>
  //       ),
  //     },
  //     {
  //       id: '3',
  //       listItem: (
  //         <Fragment>
  //           Learn about academic and <br />
  //           career pathways from quantum mentors
  //         </Fragment>
  //       ),
  //     },
  //   ],
  //   img: quantumImmersion,
  //   links: [
  //     {
  //       text: 'Learn More',
  //       link: 'https://www.qubitbyqubit.org/eqci',
  //       isExternal: true,
  //     },
  //     {
  //       text: '2025 Interest Form',
  //       link: 'https://airtable.com/appdS9n7K25uof6m4/shre71VatNJI2oo0a',
  //       isExternal: true,
  //     },
  //   ],
  // },
]

export const programPostEducators = [
  // {
  //   id: '1',
  //   title: (
  //     <Fragment>
  //       Artificial Intelligence
  //       <small className="d-block">High School Educators</small>
  //     </Fragment>
  //   ),
  //   list: [
  //     { id: '1', listItem: 'PD in AI/ML' },
  //     { id: '2', listItem: 'Bring ML into your classroom' },
  //     { id: '3', listItem: 'CEU opportunity' },
  //     { id: '4', listItem: 'STEM educators community' },
  //   ],
  //   img: ppeArtificialIntelligence,
  //   links: [
  //     { text: 'Learn More', link: '/train-2-weeks-pd', isExternal: false },
  //   ],
  // },
  {
    id: '2',
    title: (
      <Fragment>
        Machine Learning PD <span className="text-thin text-italic">with</span>{' '}
        AWS
        <small small className="d-block">
          Community College, HBCU & MSI Faculty
        </small>
      </Fragment>
    ),
    list: [
      { id: '1', listItem: '2-week Bootcamps' },
      { id: '2', listItem: 'Stipend provided' },
      { id: '3', listItem: 'CEU opportunity' },
      { id: '4', listItem: 'Learn real AI/ML skills' },
      { id: '4', listItem: 'FREE - funded by AWS' },
    ],
    img: aiCloud,
    links: [{ text: 'Learn More', link: '/train-aws-pd', isExternal: false }],
  },
  {
    id: '3',
    title: (
      <Fragment>
        Quantum Computing
        {/* <small small className="d-block">
          High School Educators
        </small> */}
        <small small className="d-block">
          High School & Above
        </small>
      </Fragment>
    ),
    subTitle: 'Sept 2024 - April 2-25',
    list: [
      { id: '1', listItem: 'Semester & year-long course options' },
      { id: '2', listItem: 'Develop skills in quantum computing' },
      { id: '3', listItem: 'Code in a real quantum computer' },
      { id: '4', listItem: 'Meet leading quantum experts' },
    ],
    img: ppeQuantumComputing,
    links: [
      {
        text: 'Learn More',
        link: 'https://www.qubitbyqubit.org/programs',
        isExternal: true,
      },
      { text: 'Register', link: '/course-application', isExternal: false },
    ],
  },
]
