import { Fragment } from "react";
import bg from "assets/img/research/university-columbia.jpg";

export const banner = {
  bg: bg,
  heading: (
    <Fragment>
      Summer Research Program <i>with </i>
      <span className="d-block text-gold">Columbia University</span>
    </Fragment>
  ),
  subHeading:
    "Develop data science skills | Conduct research with top university faculty | Increase college competitiveness |",
  description: (
    <Fragment>
      Grades: Incoming 10<sup>th</sup> - 12<sup>th</sup> + Recent High School Graduates <br />
      Dates: July 8 - August 9, 2024
    </Fragment>
  ),
  links: [
    {
      linkText: "Apply Now",
      link: "/dsrp-application",
      isExternal: false,
    },
  ],
};
