import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const CourseBenefits = (props) => {
  const { data } = props;

  return (
    <section className={styles.courseBenefitsSection}>
      <div className={classNames(styles.containerBig, "container")}>
        <h5 className={styles.sectionTitle}>{data?.sectionTitle}</h5>
        <div className="row align-items-center">
          <div className="col-12 col-md-7 mb-50 mb-md-0">
            <p className={styles.boldDesc}>{data?.sectionBoldDesc}</p>
            <p className={classNames(styles.desc, "mb-30")}>
              {data?.sectionDesc}
            </p>
            <ul
              className={classNames(styles.courseComponents, "list-unstyled")}
            >
              {data?.benefitsList?.map((item, idx) => {
                return <li key={idx}>{item}</li>;
              })}
            </ul>
            {data?.bannerLinks ? (
              <div className="container-fluid">
                <ul
                  className={classNames(
                    styles.bannerButtons,
                    "list-unstyled mb-0 pb-30"
                  )}
                >
                  {data?.bannerLinks?.map((item, idx) => {
                    return item?.isExternal ? (
                      <li key={idx}>
                        <a href={item?.link} target="_blank" rel="noreferrer">
                          {item?.linkText}
                        </a>
                      </li>
                    ) : (
                      <li key={idx}>
                        <Link to={item?.link}>{item?.linkText}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-12 col-md-5">
            <div className="img-highlighted right">
              <img
                className="img-fluid w-100"
                src={data?.img?.src}
                alt={data?.img?.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
