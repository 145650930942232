import { Fragment } from "react";

export const aiIntro = {
  paragraph: (
    <Fragment>
      AI will solve some of the most pressing issues of the 21st century. We
      want to make sure every student is prepared to become responsible AI
      leaders. That's why we've created, <i>Introduction to Machine Learning</i>
      , a 2-week bootcamp designed for community college, HBCU, & MSI university
      CS educators to gain cutting-edge knowledge and skills in Machine Learning
      (ML) in order to bring this education back into their classrooms. Offered
      virtually with live instruction, educators will work on real-world AI
      projects; hear from leading experts in AI; and join a like-minded
      community of educators.
    </Fragment>
  ),
  aiList: [
    "Learn real skills in AI/ML",
    "FREE for all accepted educators",
    "Bring ML into your classroom",
    "Stipend provided",
    "Q&As with industry experts",
  ],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{" "}
      <span className="text-black">future Responsible AI leaders!</span>
    </Fragment>
  ),
};
