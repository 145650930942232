import ajDiDonato from "assets/img/about/young-advisory-board/aj-diDonato.jpg";
import annieLuc from "assets/img/about/young-advisory-board/annie-luc.jpg";
import christieMatthews from "assets/img/about/young-advisory-board/christie-matthews.jpg";
import elonRubin from "assets/img/about/young-advisory-board/elon-rubin.jpg";
import franciscaVasconcelos from "assets/img/about/young-advisory-board/francisca-vasconcelos.jpg";
import guyCassetta from "assets/img/about/young-advisory-board/guy-cassetta.png";
import natalieEthell from "assets/img/about/young-advisory-board/natalie-ethell.jpg";
import sujayShetty from "assets/img/about/young-advisory-board/sujay-shetty.jpg";
import trevorGullstad from "assets/img/about/young-advisory-board/trevor-gullstad.jpg";
import kellyEllis from "assets/img/about/young-advisory-board/kelly-ellis.jpg";

import jpMorgan from "assets/img/common/jp-morgan.png";
import microsoft from "assets/img/common/microsoft.png";
import focalSystems from "assets/img/common/focal-systems.png";
import spotify from "assets/img/common/spotify.png";
import rhodes from "assets/img/common/rhodes.png";
import google from "assets/img/common/google.png";
import grainger from "assets/img/common/grainger.png";
import salesforce from "assets/img/common/salesforce.png";
import amazon from "assets/img/common/amazon.png";

export const youngProfessionals = [
  {
    avatar: ajDiDonato,
    name: "AJ DiDonato III.",
    organization: jpMorgan,
    organizationName: "JP Morgan",
  },
  {
    avatar: annieLuc,
    name: "Annie Luc",
    organization: microsoft,
    organizationName: "Microsoft",
  },
  {
    avatar: christieMatthews,
    name: "Christie Matthews",
    organization: focalSystems,
    organizationName: "Focal Systems",
  },
  {
    avatar: elonRubin,
    name: "Elon Rubin",
    organization: spotify,
    organizationName: "Spotify",
  },
  {
    avatar: franciscaVasconcelos,
    name: "Francisca Vasconcelos Rhodes Scholar",
    organization: rhodes,
    organizationName: "Rhodes",
  },
  {
    avatar: guyCassetta,
    name: "Guy Cassetta",
    organization: google,
    organizationName: "Google",
  },
  {
    avatar: kellyEllis,
    name: "Kelly Ellis",
    organization: amazon,
    organizationName: "Amazon",
  },
  {
    avatar: natalieEthell,
    name: "Natalie Ethell",
    organization: microsoft,
    organizationName: "Microsoft",
  },
  {
    avatar: sujayShetty,
    name: "Sujay Shetty",
    organization: grainger,
    organizationName: "Grainger",
  },
  {
    avatar: trevorGullstad,
    name: "Trevor Gullstad",
    organization: salesforce,
    organizationName: "Sales Force",
  },
];
