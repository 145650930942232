import Kate from "assets/img/train/kate-hardwood.jpg";
import Lev from "assets/img/train/paciorkowski-lev.jpg";
import { Fragment } from "react";

export const leadInstructors = {
  title: (
    <Fragment>
      <span className="text-thin d-block">2024-25 Lead </span>
      Instructors
    </Fragment>
  ),
  // desc: '(2024-25 instructors announced soon)',
  instructors: [
    {
      name: 'Kate Harwood',
      about:
        "This past year, Kate graduated from Columbia University with a Masters in Computer Science: Machine Learning. Her focus is specifically on Natural Language Processing and Ethical AI. She is interested in how Machine Learning methods can be leveraged to effect positive change, and especially interested in ensuring that those methods are fair and unbiased. Currently Kate is working with the New York Times' R&D team to integrate state-of-the-art large language models into the Times' reporting and products. Prior to graduate school, Kate was a software engineer on the Image Search team at Google.",
      img: {
        src: Kate,
        alt: "Kate Harwood",
      },
    },
    {
      name: 'Lev Paciorkowski',
      about:
        "Lev is a PhD candidate at the Washington University School of Medicine in St. Louis, where his work broadly focuses on the study, development and use of AI in cancer science. He also holds a M.S. in data science from New York University and a B.S. in Finance and Economics from the Rochester Institute of Technology. Outside of The Coding School, he taught chess for several years at the elementary and middle-school level. His favorite hobbies include taekwondo, word puzzles and hiking. Fun fact - he has four cats and two dogs (!) in family.",
      img: {
        src: Lev,
        alt: "Lev Paciorkowski",
      },
    },
  ],
};
