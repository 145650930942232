import { Fragment } from "react";

export const aiIntro = {
  paragraph:
    <Fragment>Prepare for an immersive journey into the realm of artificial intelligence with The Coding School's two-semester <span className="text-italic">Introduction to Artificial Intelligence course</span>. Tailored for high school students, this course equips learners with essential AI and machine learning (ML) skills to become the architects of tomorrow's technology. Begin unraveling the mysteries of AI, from decoding how your favorite apps predict your interests to crafting solutions that could change the world.</Fragment>,
  aiList: [
    "Learn real-world AI skills",
    "Create a capstone project",
    "Meet industry experts",
    "Become a Responsible AI Leader",
    "No prerequisites",
  ],
  paraTwo: ['With no prior coding or CS knowledge required, students dive straight into Python programming, data science fundamentals, and foundational machine learning models. Explore topics such as data visualization, decision trees, and linear regression. With strong foundations in data and machine learning, you’ll expand on these concepts alongside an international cohort of AI-pioneers, delving into advanced topics like reinforcement learning, deep learning, computer vision, and natural language processing. From building neural networks to analyzing text data, students immerse themselves in cutting-edge AI technologies, culminating in self-directed Capstone Projects that showcase their expertise and creativity.', <Fragment>Throughout the course, students will have opportunities to engage with industry experts, participate in ethical discussions, and prepare for the college application process. Students of The Coding School’s <span className="text-italic">Introduction to Artificial Intelligence</span> course are among the first wave of trailblazing students to unlock their potential in the field of AI, now offered for the first time to high school students globally. Don\'t miss the opportunity to shape the future of technology – enroll now and unlock the potential of AI and ML!</Fragment>],
  tagLine: (
    <Fragment>
      Be a part of the course created to train the nation's{" "}
      <span className="text-black">future Responsible AI leaders!</span>
    </Fragment>
  ),
};
