import { Fragment } from "react";
import { Link } from "react-router-dom";

export const faqs = [
  {
    qu: "Who is eligible to participate in this course?",
    an: "Students in high school (ages 18 years and under).",
  },
  {
    qu: "How do I know if I am eligible to receive a scholarship?",
    an: "All eligible students that are able to exemplify need and alignment with The Coding School’s mission are able to apply for a full or partial scholarship prior to the deadline on August 31, 2024. Students from traditionally underrepresented backgrounds in STEM are encouraged to apply. Completing an application does not guarantee that an applicant will receive a scholarship.",
  },
  {
    qu: "Do I have to do the homework?",
    an: "Homework is required and does count toward your grade. Students should be able to complete the homework within 30 minutes - 1 hour. Homework review sessions will be available for extra support.",
  },
  {
    qu: "Do I have to attend all lectures and labs?",
    an: "Lab attendance is mandatory. Attendance will be taken at the beginning of the lab section and will be factored into your grade. Attendence or watching the recording of the lecture is required as well.",
  },
  // {
  //   qu: "What if I have to miss a class?",
  //   an: "A recording of the lecture will be available if you have to miss the lecture. For missed lectures or labs due to an emergency, you must email the program team as soon as possible at: train@the-cs.org to request an excused absence. Documentation may be required. ",
  // },
  {
    qu: "What is the grading scale?",
    an: "We believe in creating a failure-free environment focused on learning, so this course is graded on a pass-fail scale unless your school requires a letter grade.",
  },
  {
    qu: "Is there class during school holidays?",
    an: "The course largely follows school holidays during semester one. There will be no classes or labs during the Thanksgiving week or during winter break.",
  },
  {
    qu: "Is it too late for me to enroll in the course?",
    an: "Registration for the 2024-25 course will close in September 2024."
  },
  {
    qu: "Can international students apply for the course?",
    an: "Yes, absolutely! We offer lab sections to accommodate international time zones."
  },
  {
    qu: "I'm worried I don't have enough experience for this course. Should I still sign up?",
    an: "There are no prerequisites for this course! Students with no background in STEM are encouraged to enroll."
  },
  {
    qu: "When will courses be held?",
    an: "The course runs September 2024 - April, 2025 with designated breaks for fall and winter. Additional details will be shared with students after enrollment."
  },
  {
    qu: "Can I receive credit for this course?",
    an: "This course is accredited by the Western Association of Schools and Colleges Accrediting Board (WASC) and is eligible for high school transcript credit. We can provide your school with a grade report and your school may provide you with transcript credit according to their policies. Over 300 high schools across the country are currently providing transcript credit to their students for this course. This course is also UC A-G approved as a College Preparatory Course by the University of California"
  },
  {
    qu: "Do I need to buy anything to take this course? Lab materials, a laptop, program licenses?",
    an: "Students will require access to reliable broadband on a device that is audio and video capable. Devices may include computers, laptops, notebooks, and Chromebooks. Students will need to access Zoom and Google Colab (or an alternative web-based coding platform like Jupyter or Binder) from their devices to participate fully in the course."
  },
  {
    qu: "I want to take this course with a friend, how do we sign up together?",
    an: "Once you are both enrolled in the course, please coordinate with your friend to select the same lab time."
  },
  {
    qu: "Is this the advanced version of last year's course?",
    an: "No. This is the same introductory course as last year."
  },
  {
    qu: "If I took the course last year, can I apply again?",
    an: "You may register again; however, scholarships are reserved for students who have not yet participated. Please note this is the same course as you participated in already."
  },
  {
    qu: "Is the course open to undergraduate students and above?",
    an: "No. This course is for high school students (18 years and under)."
  },
  {
    qu: "When will I hear whether my scholarship application was accepted?",
    an: "Scholarships will be distributed using a lottery system on a rolling basis until the application closes on August 31, 2024. Applicants will be notified by September 10, 2024 at the latest of one of three possible outcomes: scholarship recipient, waitlist, or not selected for a scholarship. If selected, recipients will have 5 days to accept their spot and complete associated requirements. If waitlisted, applicants will be contacted if a recipient does not accept their scholarship or if additional scholarships are funded."
  },
  {
    qu: "When will I sign up for my lab section for the course?",
    an: "Students will begin signing up for labs late summer."
  },
  {
    qu: "I'm taking the course with my school. My friend goes to another school - can they sign up too?",
    an: <Fragment>Yes! They should submit a registration form or scholarship application available <Link to="/course-application" className="text-blue-gradient-2 text-bold">here.</Link></Fragment>
  },
];
