export const courseOverview = [
  {
    tabTitle: 'Key Details',
    tabContent: {
      keyPoints: [
        {
          icon: 'fa-solid fa-map-location-dot',
          point: 'Virtual with live instruction',
        },
        {
          icon: 'fa-solid fa-calendar-days',
          point: 'July 22 - Aug 2, 2024',
        },
        { icon: 'fa-solid fa-sack-dollar', point: 'FREE for participants' },
        {
          icon: 'fa-solid fa-clipboard-list',
          point: 'Prerequisites: U.S. high school educators',
        },
      ],
    },
  },
  {
    tabTitle: 'How It Works',
    tabContent: {
      headline: 'Educators participate in three weekly components.',
      keyPoints: [
        {
          icon: 'fa-solid fa-person-chalkboard',
          point: '2 hours Lecture',
        },
        {
          icon: 'fa-solid fa-flask',
          point: '2 hours Lab with Teaching Assistant',
        },
        { icon: 'fa-solid fa-diagram-project', point: 'Homework or project' },
      ],
      description:
        'Class is held daily, Monday - Friday, 11-3pm ET (8am-12pm PST). At the end of the course, educators will have ML skills and knowledge to take back into their classrooms to continue the education for their students.',
      highlightedPoint: [
        // "Upon successful completion of the 2-week course, educators will have the opportunity to receive CEUs.",
      ],
    },
  },
  {
    tabTitle: "What you'll learn",
    tabContent: {
      descriptions: [
        "Educators will develop foundational skills in Python, data science, and machine learning. They'll experience AI at the intersection of fields such as healthcare, robotics and social media.",
        'One of the most important topics covered in the course is bias in AI in which educators will learn what it means to become a Responsible AI leader.',
      ],
      pointsList: [
        'ML Algorithms, such as KNN and k-means clustering',
        'Data visualizations',
        // 'Reinforcement Learning;',
        // 'Deep Learning;',
        // 'Natural Language Processing (NLP)',
      ],
    },
  },
  {
    tabTitle: 'How To Apply',
    tabContent: {
      descriptions: [
        'This course is open to public school or public charter 9th-12th grade educators within the U.S.',
        'We encourage educators serving students who are underrepresented in STEM or those located in STEM deserts to apply.',
      ],
      highlightedPoint: [
        'No prior coding experience required.',
        'Applicants must create an account in the Pathfinders Online Institute Portal to access the application. Click apply button below to create your account and submit your application.',
      ],
      button: {
        isExternal: true,
        buttonText: 'Create Account & Apply',
        link: 'https://pathfinders.onwingspan.com/web/en/app/event-details/theCodingSchool',
      },
    },
  },
]
