import { Fragment } from "react";

export const banner = {
  bannerTitle: "AI will change",
  rotatingWords: [
    "Healthcare",
    "Finance",
    "Policymaking",
    "Conservation",
    "Defense",
    "Education",
    "Aerospace",
    "Entertainment",
  ],
  bannerSubTitle: "BE A PART OF THE AI REVOLUTION.",
  additionalData: [<Fragment><h3 style={{ fontSize: '44px' }} className="font-seg-ui-bold my-30">Introduction to Artificial Intelligence <br/> <small>for High School Students</small></h3> </Fragment >, <Fragment><h3 className="font-seg-ui-reg">September 29, 2024 - April 2025</h3></Fragment>],
  button: {
    isExternal: false,
    title: "Register",
    link: "/course-application",
  },
};
