import { Fragment } from "react";
import bg from "assets/img/summer-programs/artificial-intelligence.jpg";

export const banner = {
  bannerBg: bg,
  heading: "SUMMER OF EMERGING TECH",
  programs:
    "Artificial Intelligence | Quantum Computing | Data Science | Art+Tech | Cybersecurity | Web Development",
  programsTypes: "Virtual Camps, Intensives & Research Opportunities",
  programsFor: "Grades: 6 – 12, University + Educators",
  bannerLinks: [
    {
      linkText: (
        <Fragment>
          <span>
            <span>Quantum Camps</span>
            Register Now
          </span>
        </Fragment>
      ),
      link: "/summer-camp-registration",
      isExternal: false,
    },
    {
      linkText: (
        <Fragment>
          <span>
            <span>AI, EmergingTech, and more</span>
            Register Now
          </span>
        </Fragment>
      ),
      link: "/summer-camp-registration",
      isExternal: false,
    },
    {
      linkText: (
        <Fragment>
          <span>
            <span>Data Science Research</span>
            Apply Now
          </span>
        </Fragment>
      ),
      link: "/dsrp-application",
      isExternal: false,
    },
    {
      linkText: (
        <Fragment>
          <span>
            <span>Summer Camps</span>
            Learn more
          </span>
        </Fragment>
      ),
      link: "virtualSummerCamps",
      isExternal: false,
      isScrollable: true,
    },
    // {
    //   linkText: (
    //     <Fragment>
    //       <span>Request a brochure</span>
    //     </Fragment>
    //   ),
    //   link: "https://share.hsforms.com/1cfbutDoSTlSUYr-qkEBp7wd0fk5",
    //   isExternal: true,
    // },
  ],
};
