import { useCallback } from 'react'

import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

import { HomeBannerAnimation } from './components/HomeBannerAnimation/HomeBannerAnimation'

import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

export const HomeBanner = () => {
  const particlesInit = useCallback(async engine => {
    await loadFull(engine)
  }, [])
  return (
    <div className={styles.homeBanner}>
      <div className={styles.particlesArea}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: false,
              zIndex: 0,
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: 'push',
                },
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 2,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              links: {
                color: '#ffffff',
                distance: 100,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: 'none',
                enable: true,
                outModes: {
                  default: 'bounce',
                },
                random: false,
                speed: 0.4,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 100,
              },
              opacity: {
                value: 0.3,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-7 col-md-12"
            data-aos="fade-left"
            data-aos-duration="500"
          >
            <h2 className="text-white">
              <span className="heading-small d-block">
                Training the future workforce in
              </span>
              <span className="text-bolder text-gold">
                emerging technologies
              </span>
            </h2>
            <p>
              Creating pipelines and pathways for learners at every stage: K-16,
              educators, and the workforce
            </p>
            {/* <p className={styles.bannerDisclaimer + " text-white d-block"}>
              Instructor-led virtual courses, workshops, & research programs
              offered year-round
            </p> */}
            <p className={styles.bannerDisclaimer + ' text-white d-block'}>
            Registration now open for 2024-25 courses in AI and Quantum Computing - Sign Up Today!
            </p>
            <div className={styles.buttonsWrap}>
              <Link to={'/courses'} className="btn btn-gradient-2">
                Learn More
              </Link>
              <Link to="/course-application" className="btn btn-gradient-2">
                Register
              </Link>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-12 anim_img d-none d-lg-flex"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <HomeBannerAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}
