import { Fragment } from "react";

import WASC from "assets/img/common/wasc.png";
import AG from "assets/img/common/uc-ag-guide.png";

export const forSchools = {
  sectionTitle: {
    thinText: 'Accredited',
    regularText: 'Course',
  },
  sectionDesc: 'This course is nationally accredited and students may petition their school to receive high school transcript credit for taking this course.',
  buttonLink: "https://airtable.com/shrKuRrP6Kv1oNnNe",
  logos: [
    { src: WASC, alt: "WASC" },
    { src: AG, alt: "University Of California A-G Guide" },
  ],
  button: [
    {
      external: false,
      text: 'Register',
      link: '/course-application',
    },
  ],
};
