import { Fragment } from "react";

export const courseOverview = [
  {
    tabTitle: "Key Details",
    tabContent: {
      keyPoints: [
        {
          icon: "fa-solid fa-map-location-dot",
          point: "Virtual with live instruction",
        },
        {
          icon: "fa-solid fa-calendar-days",
          point: <Fragment>Fall 2024 <br />Sept 29 - Dec 2024</Fragment>,
        },
        { icon: "fa-solid fa-sack-dollar", point: <Fragment>Tuition: $995 <br /> Scholarships available</Fragment> },
        { icon: "fa-solid fa-clipboard-list", point: <Fragment>No prerequisites <span className="d-block mt-10">Open to high school students (18 years & under)</span></Fragment> },
      ],
    },
  },
  {
    tabTitle: "How It Works",
    tabContent: {
      headline: "Students participate in three weekly components.",
      keyPoints: [
        {
          icon: "fa-solid fa-person-chalkboard",
          point: "1.5 hour Lecture",
        },
        {
          icon: "fa-solid fa-flask",
          point: "1 hour Lab with Teaching Assistant",
        },
        { icon: "fa-solid fa-diagram-project", point: "Homework or project" },
      ],
      description:
        "Additionally, students will have the opportunity to hear from AI experts from academia and industry, meet with college admissions officers from universities around the U.S., join affinity groups, as well as participate in regional meet-ups to meet students in your local community. For supplemental academic support, we'll have weekly office hours and homework review sessions.",
      highlightedPoint: [
        "Upon completion of the course students will be presented with a Certificate of Completion",
        "An optional Introduction to ML Part II Bootcamp will follow October 13 - 26, 2024 in order to reinforce and further advance skills in ML.",
        "Labs: multiple time options available Tuesday-Saturday",
        "Orientation & pre-course sessions start Sept 2024",
      ],
    },
  },
  {
    tabTitle: "What Students Learn",
    tabContent: {
      descriptions: [
        "In Semester 1, students focus on building a strong understanding of the core principles and techniques used in data science and machine learning. Students are introduced to key concepts of programming, data analysis and visualization, and the machine learning process and traditional models.",
        "In addition to learning real AI skills, students will hear from AI experts at top universities and companies. Sessions with college admissions officers will also be held to provide invaluable guidance for the college admission process. One of the most important topics students will study in the course is bias in AI in which they will learn what it means to become a Responsible AI leader.",
      ],
      topicsCovered: "Key concepts covered:",
      pointsList: [
        "Machine Learning",
        "Data Visualizations",
        "Linear Regression",
      ],
    },
  },
  {
    tabTitle: "How To Register",
    tabContent: {
      descriptions: [
        "This course is open to U.S. and international high school (18 years or under) students. To enroll, students are required to complete a short registration form with basic information and pay tuition in full at the time of registration or sign up for a payment plan. If you are unable to afford the cost of tuition, you can apply for a full or partial scholarship. The scholarship application can be found within the same registration form. Students from backgrounds traditionally underrepresented in STEM are encouraged to enroll.",
        <Fragment> <span className="text-bold">No prior coding experience required, so sign up today!</span></Fragment>,
      ],
      button: {
        isExternal: true,
        link: "https://airtable.com/appdS9n7K25uof6m4/shrk9rCX4MuD6T0HY",
        buttonText: "Interest Form",
      },
    },
  },
  {
    tabTitle: "Scholarships",
    tabContent: {
      descriptions: [
        "Limited scholarship funds are available to cover the cost of tuition for eligible students that exemplify need and alignment with The Coding School’s mission. The deadline to apply for a scholarship is August 31, 2024. Scholarship awardees will be notified by September 10, 2024.",
        "Completing an application does not guarantee that an applicant will receive a scholarship. Scholarships will be distributed using a lottery system on a rolling basis until the scholarship application closes on August 31, 2024. Applicants will be notified by September 10, 2024 at the latest of one of three possible outcomes: scholarship recipient, waitlist, or not selected for a scholarship. If selected, recipients will have 5 days to accept their spot and complete associated requirements. If waitlisted, applicants will be contacted if a recipient does not accept their scholarship or if additional scholarships are funded."
      ],
      highlightedPoint: [
        <Fragment>
          <span className="text-bold">
            Students from backgrounds traditionally underrepresented in STEM are
            encouraged to apply!
          </span>
        </Fragment>,
        "No prior coding experience required, so submit your application today!",
      ],
      button: {
        isExternal: false,
        link: "/course-application",
        buttonText: "Apply",
      },
    },
  },
];
