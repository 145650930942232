import { Fragment } from 'react'

export const headerLinks = [
  { label: 'Home', link: '/', isExternal: false },
  { label: 'About Us', link: '/about_us', isExternal: false },
  {
    label: 'Programs',
    isExternal: 'false',
    dropdownItems: [
      { label: '2024-25 Courses', link: '/courses', isExternal: false },
      {
        label: <Fragment>Artificial Intelligence</Fragment>,
        link: '/train',
        isExternal: false,
      },
      {
        label: 'Quantum Computing',
        link: 'https://www.qubitbyqubit.org/programs',
        isExternal: true,
      },
      { label: 'Summer Programs', link: '/summer-programs', isExternal: false },
      // {
      //   label: "CodeConnects",
      //   link: "https://codeconnects.org",
      //   isExternal: true,
      // },
      {
        label: 'Research',
        link: '/research',
        isExternal: false,
      },
    ],
  },
  { label: 'Donate', link: '/donate', isExternal: false },
  { label: 'Get involved', link: '/involved', isExternal: false },
  { label: 'News', link: 'http://blog.the-cs.org/', isExternal: true },
  {
    label: 'Store',
    link: 'https://the-coding-school-store.sellfy.store/',
    isExternal: true,
    newTab: true,
  },{
    label: 'Register',
    link: '/course-application',
    isExternal: false,
    isButton: 'px-20 ml-4 btn btn-gradient-2 rounded-0 text-white d-inline-block register-btn',
  },
  // {
  //   label: 'Register',
  //   isExternal: 'false',
  //   isButton:
  //     'px-20 ml-4 btn btn-gradient-2 rounded-0 text-white d-inline-block register-btn',
  //   dropdownItems: [
  //     {
  //       label: 'Virtual Camps',
  //       link: '/summer-camp-registration',
  //       isExternal: false,
  //     },
  //     {
  //       label: 'Data Science Research',
  //       link: '/dsrp-application',
  //       isExternal: false,
  //     },
  //     // { label: "EQCI", link: "/eqci-application", isExternal: false, },
  //     {
  //       label: 'Quantum Professional Development',
  //       link: '/quantum-pd-application',
  //       isExternal: false,
  //     },
  //     {
  //       label: (
  //         <Fragment>
  //           AI Professional Development{' '}
  //           <small className="d-block mt-5 text-blue-gradient">
  //             (For High School Educators)
  //           </small>
  //         </Fragment>
  //       ),
  //       link: 'https://pathfinders.onwingspan.com/web/en/login?ref=%2Fapp%2Fevent-details%2FtheCodingSchool',
  //       isExternal: true,
  //     },
  //     {
  //       label: (
  //         <Fragment>
  //           AWS ML Professional Development{' '}
  //           <small className="d-block mt-5 text-blue-gradient">
  //             (For College Faculty)
  //           </small>
  //         </Fragment>
  //       ),
  //       link: '/train-aws-pd-application',
  //       isExternal: false,
  //     },
  //     {
  //       label: '2024-25 Courses',
  //       link: '/course-application',
  //       isExternal: false,
  //     },
  //   ],
  // },
]
