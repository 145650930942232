import { Fragment } from 'react'

import quantumComputing from 'assets/img/home/upcoming-programs-5.jpg'
import ai from 'assets/img/courses/ai.jpg'

import semesterQuantumComputing from 'assets/img/courses/quantum-computing.jpg'
import semesterAI from 'assets/img/home/upcoming-programs-1-new.jpg'

export const emergingTech = {
  sectionData: {
    sectionId: 'techCourses',
    sectionHeading: (
      <Fragment>
        Our Emerging{' '}
        <span className="text-animated">
          <span>Tech Courses</span>
        </span>
      </Fragment>
    ),
    sectionDescription: (
      <Fragment>
        Accredited by WASC & partnered with the White House, DoD, Microsoft,
        Google Quantum AI, IBM Quantum, Stanford & more, we offer the most{' '}
        <span className="text-bold text-italic text-blue-gradient">
          <span>supportive, in-depth & engaging</span>
        </span>{' '}
        tech education for K-12 & beyond.
      </Fragment>
    ),
  },
  coursesData: [
    {
      sectionTitle: 'Academic year courses',
      cards: [
        {
          isInverted: false,
          cardImg: quantumComputing,
          cardTitle: 'Introduction to Quantum Computing',
          cardSubtitle: (
            <Fragment>
              Semesters I & II
              <span className="d-block">Grades 9 - 12 & Above</span>
              <span className="d-block">September 2024 - April 2025</span>
            </Fragment>
          ),
          keyPoints: [
            'Be among the first students in the world to learn quantum computing',
            'Learn in-depth skills applying quantum computing',
            'Discover the magic of quantum hardware',
            'Meet renown quantum experts',
            'Go on virtual quantum lab tours',
            'Run code on a quantum computer!',
          ],
          button: [
            {
              external: true,
              text: 'Learn more',
              link: 'https://www.qubitbyqubit.org/course-info',
            },
            {
              external: false,
              text: 'Register',
              link: '/course-application',
            },
          ],
        },
        {
          isInverted: true,
          cardImg: ai,
          cardTitle: 'Introduction to Artificial Intelligence',
          cardSubtitle: (
            <Fragment>
              Semesters I & II
              <span className="d-block">Grades: 9 - 12</span>
              <span className="d-block">September 2024 - April 2025</span>
            </Fragment>
          ),
          keyPoints: [
            'For-credit course',
            'Develop advanced skills in AI',
            'Hands-on applications with ML algorithms such as KNN and k-means clustering',
            'Gain skills in computer vision, natural language processing, and deep learning',
            'Create AI capstone project',
          ],
          button: [
            {
              external: false,
              text: 'Learn more',
              link: '/train-course',
            },
            {
              external: false,
              text: 'Register',
              link: '/course-application',
            },
          ],
        },
      ],
    },
    {
      sectionTitle: 'Semester Courses',
      cards: [
        {
          isInverted: false,
          cardImg: semesterQuantumComputing,
          cardTitle: 'Foundations of Quantum Computing',
          cardSubtitle: (
            <Fragment>
              Semester I of Introduction to Quantum Computing
              <span className="d-block">Grades 9 - 12 & Above</span>
              <span className="d-block">September - December 2024</span>
            </Fragment>
          ),
          keyPoints: [
            'Explore the world of quantum ',
            'Learn foundational skills in quantum mechanics',
            'using qubits, superpositon and entanglement concepts',
            'Discuss ethics and biases to use quantum responsibly',
            'Develop Python skills',
            'Options to continue on to Semester II',
          ],
          button: [
            {
              external: true,
              text: 'Learn more',
              link: 'https://www.qubitbyqubit.org/semester-course-info',
            },
            {
              external: false,
              text: 'Register',
              link: '/course-application',
            },
          ],
        },
        {
          isInverted: true,
          cardImg: semesterAI,
          cardTitle: 'Foundations of Data Science & Machine Learning',
          cardSubtitle: (
            <Fragment>
              Semester I of Introduction to Artificial Intelligence
              <span className="d-block">Grades: 9 - 12</span>
              <span className="d-block">September - December 2024</span>
            </Fragment>
          ),
          keyPoints: [
            'Develop foundational data science skills ',
            'Create visualizations & ML models',
            'Learn Responsible AI',
            'Industry expert Q&As',
            'Gain skills in Python',
            'Options to continue on to Semester II',
          ],
          button: [
            {
              external: false,
              text: 'Learn more',
              link: '/train-semester-course',
            },
            {
              external: false,
              text: 'Register',
              link: '/course-application',
            },
          ],
        },
      ],
    }
  ],
}
